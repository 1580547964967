import { Component, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { filter, first, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { CookieOptions, CookieService } from 'ngx-cookie-service';

import { AuthenticationService } from '../shared/services/authentication.service';
import { NotificationService } from '../notification/notification.service';
import { CommonService } from '../shared/services/common-service.service';
import { environment } from '../../environments/environment';
import { AUTH_PROMPTS } from '../shared/ui-strings';

@Component({ 
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  authPrompts = AUTH_PROMPTS;
  loginForm: FormGroup;
  fetchData: boolean;
  displayManageUser: boolean;
  loading = false;
  submitted = false;
  userName: string;
  returnUrl: string;
  resetPasswordUrl: string = environment.RESET_PASSWORD;
  authenticationSubscription$: Subscription;
  loginLoader: string = 'true';
  private readonly _destroying$ = new Subject<null>();
  loginStatus = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private notificationSvc: NotificationService,
    private commonService: CommonService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private cookieService: CookieService
  ) {};

  ngOnInit(): void {
    if(this.authenticationService.isMsalLoggedIn()) {
      sessionStorage.setItem('isAuthLoading', 'true');
    }
    else {
      if(!sessionStorage.getItem('isAuthLoading')){
        sessionStorage.setItem('isAuthLoading', 'false');
      }
      this.loginLoader = sessionStorage.getItem('isAuthLoading')!;
    }

    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.msalService.handleRedirectObservable().subscribe({
      next: (result) => {
        if (!result) {
          this.setSessionStorageAndLoader("false");
        }
      },
      error: () => {
        this.setSessionStorageAndLoader("false");
      }
    });

    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
    )
    .subscribe((result: EventMessage) => {
      if (this.msalService.instance.getAllAccounts().length === 0) {
        this.router.navigate(['/login']);
      } else {
        this.setLoginDisplay();
      }
    });

    this.msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.setLoginDisplay();
    })
  }

  // convenience getter for easy access to form fields
  get f(): FormGroup["controls"] {
    return this.loginForm.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.commonService.setUserName(this.f.username.value);

    this.fetchData = true;
    this.authenticationSubscription$ = this.authenticationService
      .login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe({
        next: (data) => {
          this.commonService.changeData(data['HOME'].some(element => element === 'MANAGE_USERS_1'));
          this.router.navigate(['/']).then(() => this.fetchData=false);
        },
        error: (error) => {
          this.notificationSvc.error(error, 20000);
          this.fetchData = false;
        }
      });
  }

  onSsoLogin() {
    this.setSessionStorageAndLoader("true");
    try {
      this.authenticationService.loginSso();
    } catch (error) {
      this.setSessionStorageAndLoader("false");
    }
  }

  async setLoginDisplay() {
    this.loginStatus = this.msalService.instance.getAllAccounts().length > 0;

    if(this.loginStatus){
      const profile_data = this.msalService.instance.getAllAccounts()[0];
      this.msalService.instance.setActiveAccount(profile_data);

      try {
        if (!this.authenticationService.isMsalLoggedIn()) {
          this.setMsalToken();
        }
        else {
          this.loginUsingSso();
        }
      } catch (error) {
        this.setSessionStorageAndLoader("false");
      }
    } else {
      if(this.authenticationService.isMsalLoggedIn()) {
        await this.onSsoLogin();

        this.setSessionStorageAndLoader("false");
      }
      this.router.navigate(['/login']);
    }
  }

  setMsalToken() {
    const tokenRequest = {
        scopes: ["user.read"],
        account: this.msalService.instance.getAllAccounts()[0]
    }
    this.msalService.acquireTokenSilent(tokenRequest).subscribe((tokenResponse) => {
        if (tokenRequest != null) {
          const domain = this.getDomainFromURL(window.location.hostname);
          const expiry = new Date((new Date()).getTime() + 3600 * 1000);
          let cookieOptions: CookieOptions = {
            domain: domain,
            secure: true,
            sameSite: 'None',
            expires: expiry
          }
          this.cookieService.set(
            'msal-access-token', 
            JSON.stringify({token: tokenResponse.accessToken, expiry: expiry}), cookieOptions
          );
          this.cookieService.set(
            'msal-id-token', 
            JSON.stringify({token: tokenResponse.idToken, expiry: expiry}), cookieOptions
          );
          
          this.loginUsingSso();
        }
    })
  }

  loginUsingSso() {
    this.authenticationService.loginUsingSso({
      accessToken: JSON.parse(this.cookieService.get('msal-access-token')).token,
      idToken: JSON.parse(this.cookieService.get('msal-id-token')).token,
      email: this.msalService.instance.getActiveAccount()?.username!
    }).subscribe({
      next: (result) => {
        this.setSessionStorageAndLoader("false");
        this.router.navigate(['/']);
      },
      error: () => {
        this.setSessionStorageAndLoader("false");
      }
    });
  }

  private setSessionStorageAndLoader(value: string) {
    this.loginLoader = value;
    sessionStorage.setItem('isAuthLoading', value);
  }

  private getDomainFromURL(url: string) {
    const parts = url.split('.');
    if(parts.length > 2) 
      return parts.slice(-2).join('.');
    else
      return parts[0];
  }

  ngOnDestroy(): void {
    this.authenticationSubscription$?.unsubscribe();
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
